import {
  AssignmentInd,
  Bookmark,
  CalendarMonth,
  Call,
  CheckCircle,
  ConfirmationNumber,
  EmojiEvents,
  EventAvailable,
  Home,
  LocalOffer,
  Loyalty,
  ManageAccounts,
  Monitor,
  Redeem,
  SportsGolf,
  Store,
  SupervisedUserCircle,
  VerifiedUser,
  VerifiedUserRounded,
  ViewListOutlined,
} from "@mui/icons-material";

import { ReactNode } from "react";
import SidebarDesktop from "./SidebarDesktop";
import SidebarMobile from "./SidebarMobile";
import { TPermission } from "interfaces/user";
import useResponsive from "hooks/useResponsive";

interface Props {
  drawerWidth: number;
  isOpen: boolean;
  toggleDrawer: (
    isOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export interface IMenu {
  header?: string;
  permission?: TPermission;
  items: {
    icon: ReactNode;
    label: string;
    href?: string;
    permission?: TPermission;
    subMenu?: {
      icon: ReactNode;
      label: string;
      href: string;
      permission?: TPermission;
    }[];
  }[];
}

const menu: IMenu[] = [
  {
    items: [
      {
        icon: <Home />,
        label: "Dashboard",
        href: "/dashboard",
      },
      {
        icon: <ManageAccounts />,
        label: "Manage Admin",
        permission: "MANAGE_ADMIN",
        subMenu: [
          {
            icon: <SupervisedUserCircle />,
            label: "Admin",
            href: "/admin/list",
          },
          {
            icon: <AssignmentInd />,
            label: "Role",
            href: "/role/list",
          },
          {
            icon: <VerifiedUser />,
            label: "Permission",
            href: "/permission/list",
          },
        ],
      },
    ],
  },
  {
    header: "Only Golf",
    permission: "MANAGE_ONLY_GOLF",
    items: [
      {
        icon: <Home />,
        label: "Dashboard",
        href: "/dashboard/only-golf",
      },
      {
        icon: <SupervisedUserCircle />,
        label: "Manage Member",
        subMenu: [
          {
            icon: <SupervisedUserCircle />,
            label: "Member",
            href: "/member/only-golf/list",
          },
          {
            icon: <SupervisedUserCircle />,
            label: "Member Log",
            href: "/member-log/only-golf/list",
          },
        ],
      },
      {
        icon: <CalendarMonth />,
        label: "Club Fitting",
        subMenu: [
          {
            icon: <CalendarMonth />,
            label: "Calendar",
            href: "/calendar/only-golf",
          },
          {
            icon: <EventAvailable />,
            label: "Booking",
            href: "/booking/only-golf/list",
          },
          {
            icon: <ViewListOutlined />,
            label: "Service",
            href: "/service/only-golf/list",
          },
        ],
      },
      {
        icon: <Store />,
        label: "Store",
        href: "/store/only-golf/list",
      },
      {
        icon: <LocalOffer />,
        label: "Promotion",
        href: "/promotion/only-golf/list",
      },
      {
        icon: <EmojiEvents />,
        label: "Manage Reward",
        subMenu: [
          {
            icon: <EmojiEvents />,
            label: "Reward",
            href: "/reward/only-golf/list",
          },
          {
            icon: <Redeem />,
            label: "Reward Redemption",
            href: "/reward-redemption/only-golf/list",
          },
        ],
      },
      {
        icon: <Loyalty />,
        label: "Manage Coupon",
        subMenu: [
          {
            icon: <Bookmark />,
            label: "Coupon Type",
            href: "/coupon-type/only-golf/list",
          },
          {
            icon: <Loyalty />,
            label: "Coupon",
            href: "/coupon/only-golf/list",
          },
          {
            icon: <Redeem />,
            label: "Coupon Redemption",
            href: "/coupon-redemption/only-golf/list",
          },
        ],
      },
    ],
  },
  {
    header: "Club Fitting",
    permission: "MANAGE_CLUB_FITTING",
    items: [
      {
        icon: <CalendarMonth />,
        label: "Calendar",
        href: "/calendar/club-fitting",
      },
      {
        icon: <EventAvailable />,
        label: "Booking",
        href: "/booking/club-fitting/list",
      },
      {
        icon: <ViewListOutlined />,
        label: "Service",
        href: "/service/club-fitting/list",
      },
    ],
  },
  {
    header: "Simulator Bay",
    permission: "MANAGE_SIMULATOR_BAY",
    items: [
      {
        icon: <Home />,
        label: "Dashboard",
        href: "/simulator-bay/dashboard",
      },
      {
        icon: <SupervisedUserCircle />,
        label: "Manage Member",
        subMenu: [
          {
            icon: <SupervisedUserCircle />,
            label: "Member",
            href: "/simulator-bay/member",
          },
          {
            icon: <SupervisedUserCircle />,
            label: "Member Log",
            href: "/simulator-bay/member-log",
          },
        ],
      },
      {
        icon: <EventAvailable />,
        label: "Booking",
        href: "/simulator-bay/booking",
      },
      {
        icon: <Monitor />,
        label: "Bay",
        href: "/simulator-bay/bay",
      },
      {
        icon: <VerifiedUserRounded />,
        label: "Professional",
        href: "/simulator-bay/professional",
      },
    ],
  },
];

const Sidebar: React.FC<Props> = ({ drawerWidth, isOpen, toggleDrawer }) => {
  return (
    <>
      {useResponsive("up", "lg") ? (
        <SidebarDesktop drawerWidth={drawerWidth} menu={menu} />
      ) : (
        <SidebarMobile
          drawerWidth={drawerWidth}
          menu={menu}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      )}
    </>
  );
};

export default Sidebar;
