import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import CouponServices from "services/coupon";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICoupon } from "interfaces/coupon";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "components/common/Table";
import { IUser } from "interfaces/user";
import { IService } from "interfaces/service";
import AvatarLetter from "components/common/AvatarLetter";
import Swal from "sweetalert2";
import { Close, Download, UploadFile } from "@mui/icons-material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import * as XLSX from "xlsx";
import CouponCodeServices from "services/couponCode";
import { ICouponCode } from "interfaces/couponCode";

const CouponDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [coupon, setCoupon] = useState<ICoupon | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CouponServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          setCoupon(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  if (coupon) {
    return (
      <>
        <Header
          title="Coupon Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Coupon",
              href: `/coupon/only-golf/list`,
            },
            {
              label: coupon.name,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Cover Image",
              type: "image",
              name: "image",
              defaultValue: coupon.image,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Type",
              type: "text",
              name: "coupon_type",
              defaultValue: coupon.coupon_type.name,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Mode",
              type: "text",
              name: "mode",
              defaultValue: coupon.mode,
              col: 6,
            },
            {
              label: "Confirm Code",
              type: "text",
              name: "confirm_code",
              defaultValue: coupon.confirm_code,
              col: 6,
            },
            {
              label: "Coupon Name",
              type: "text",
              name: "name",
              defaultValue: coupon.name,
              col: 6,
            },
            {
              label: "Prefix",
              type: "text",
              name: "prefix",
              defaultValue: coupon.prefix,
              col: 6,
            },
            {
              label: "Point",
              type: "number",
              name: "point",
              defaultValue: coupon.point,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Start At",
              type: "date",
              name: "started_at",
              defaultValue: coupon.started_at,
              col: 6,
            },
            {
              label: "End At",
              type: "date",
              name: "ended_at",
              defaultValue: coupon.ended_at,
              col: 6,
            },
            {
              label: "Short Description",
              type: "textarea",
              name: "short_description",
              defaultValue: coupon.short_description,
              col: 6,
            },
            {
              label: "Long Description",
              type: "text-editor",
              name: "description",
              defaultValue: coupon.description,
              col: 6,
            },
            {
              label: "1 Time Per Month",
              type: "radiogroup",
              name: "is_user_per_month",
              col: 6,
              defaultValue: coupon.is_user_per_month ? "yes" : "no",
              items: [
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ],
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Supply",
              type: "number",
              name: "limit",
              defaultValue: coupon.limit,
              col: 6,
            },
            {
              label: "Limit Per User",
              type: "number",
              name: "limit_per_user",
              defaultValue: coupon.limit_per_user,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/coupon/only-golf/list`)}
          content={coupon.mode === "FIXED" ? <CouponCodeSection /> : null}
        />
      </>
    );
  } else {
    return <></>;
  }
};

interface ICouponCodeData {
  id: number;
  code: string;
  member: string;
  redeemed_at: string;
  created_at: string;
  deleted_at: string;
  created_by: IUser;
  deleted_by: IUser | null;
}

const CouponCodeSection: React.FC = () => {
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: any[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 5,
  });
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    CouponCodeServices.getAll(
      token as string,
      id as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICouponCode) => {
            return {
              id: d.id,
              code: d.code,
              member: d.member
                ? d.member.first_name + " " + d.member.last_name
                : "-",
              redeemed_at: d.redeemed_at
                ? new Date(d.redeemed_at).toLocaleString("th-TH")
                : "-",
              created_at: d.created_at,
              deleted_at: d.deleted_at,
              created_by: d.created_by,
              deleted_by: d.deleted_by,
            } as ICouponCodeData;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [token, onAlert, id, pagination, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = (item: ICouponCodeData) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการลบโค้ดนี้ใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CouponCodeServices.delete(token as string, id as string, item.id)
          .then((res) => {
            fetchData();
            onAlert("success", "ลบสำเร็จ");
            setIsLoading(false);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Header
        title="Code Log"
        breadcrumbs={[]}
        actions={[
          <a href="/import_template.xlsx" download>
            <Button
              variant="outlined"
              size="large"
              onClick={() => setIsOpenModal(true)}
            >
              <Download
                sx={{
                  fontSize: 20,
                  mr: 1,
                }}
              />
              <Typography>Template</Typography>
            </Button>
          </a>,
          <Button
            variant="contained"
            size="large"
            onClick={() => setIsOpenModal(true)}
          >
            <UploadFile
              sx={{
                fontSize: 20,
                mr: 1,
              }}
            />
            <Typography>Import Data</Typography>
          </Button>,
        ]}
      />
      {isOpenModal ? (
        <ImportCodeModal
          onClose={() => setIsOpenModal(false)}
          fetchData={fetchData}
        />
      ) : null}
      <Table
        headers={[
          {
            key: "code",
            label: "Code",
          },
          {
            key: "member",
            label: "Member",
          },
          {
            key: "redeemed_at",
            label: "Redeemed At",
          },
          {
            key: "created_by",
            label: "Created By",
            render: (value: IUser, index, item: ICouponCode) => (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                  }}
                >
                  <AvatarLetter label={value.name} tooltip center />
                </Box>
                <Typography>
                  {new Date(item.created_at).toLocaleString("th-TH")}
                </Typography>
              </Box>
            ),
          },
        ]}
        actions={{
          others: [
            {
              icon() {
                return <Close />;
              },
              label: () => "",
              onClick(item: ICouponCodeData) {
                onDelete(item);
              },
              hidden(item: ICouponCodeData) {
                return item.member !== "-";
              },
            },
          ],
        }}
        data={data.data}
        isLoading={isLoading}
        onSearch={(searchText) => setSearch(searchText)}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </Box>
  );
};

interface IImportCodeModal {
  onClose: () => void;
  fetchData: () => void;
}

const ImportCodeModal: React.FC<IImportCodeModal> = ({
  onClose,
  fetchData,
}) => {
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [codes, setCodes] = useState<string[]>([]);
  const [error, setError] = useState<{
    message: string;
    data: string[];
  } | null>(null);

  const readUploadFile = (event: any) => {
    event.preventDefault();
    if (event.target.files) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const dataDefault: string[] = json.map((d: any) => d["Code"]);
        setFileName(event.target.files[0].name);
        setCodes(dataDefault);
        setError(null);
        setIsLoading(false);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const onSubmit = async () => {
    console.log("codes: ", codes);
    Swal.fire({
      icon: "warning",
      html: `ต้องการเพิ่มข้อมูล ${codes.length} รายการใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CouponCodeServices.import(token as string, id as string, codes)
          .then((res) => {
            if (res.data.data != null) {
              setError(res.data);
            } else {
              onAlert("success", "สร้างรายการสำเร็จ");
              fetchData();
              onClose();
            }
          })
          .catch((err) => {
            onAlert("error", "Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ pb: 2 }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            component="label"
            disabled={isLoading}
            sx={{
              mr: 2,
            }}
          >
            <UploadFile
              sx={{
                fontSize: 20,
                mr: 1,
              }}
            />
            <Typography>Import</Typography>
            <input
              type="file"
              name="upload"
              id="upload"
              accept=".csv,.xlsx"
              onChange={readUploadFile}
              hidden
            />
          </Button>
          <p>{fileName}</p>
        </Box>
        {error ? (
          <div
            style={{
              border: "1px solid red",
              borderRadius: "12px",
              padding: "8px",
              marginTop: "16px",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <p>
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Error:
              </span>
              {error.message}
            </p>
            <ul>
              {error.data.map((d) => {
                return <li style={{ whiteSpace: "pre" }}>{d}</li>;
              })}
            </ul>
          </div>
        ) : null}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
        <Button variant="outlined" size="large" onClick={onClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading || codes.length === 0}
          onClick={onSubmit}
        >
          <Typography>
            {isLoading ? "Loading..." : `Create (${codes.length})`}
          </Typography>
        </Button>
      </Box>
    </Paper>
  );
};

export default withAuth(withPermission(CouponDetail, "MANAGE_ONLY_GOLF"));
