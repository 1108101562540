import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const OGBookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/og-booking`,
});

const OGBookingServices = {
  getDetail: (token: string, id: string) =>
    OGBookingService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAllBooking: (token: string) =>
    OGBookingService.get(`/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest,
    search: string,
    dateRange: {
      start: string;
      end: string;
    }
  ) =>
    OGBookingService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (
    token: string,
    data: {
      first_name: string;
      last_name: string;
      email?: string;
      tel: string;
      booking_date: string;
      services: number[];
      start_slot_time_id: number;
    }
  ) =>
    OGBookingService.post(``, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  cancel: (token: string, id: string | number) =>
    OGBookingService.put(`/${id}/cancel`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: {
      first_name: string;
      last_name: string;
      tel: string;
      email?: string;
      services: number[];
    }
  ) =>
    OGBookingService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    OGBookingService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default OGBookingServices;
