import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const couponCodeService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/coupon`,
});

const CouponCodeServices = {
  getAll: (
    token: string,
    coupon_id: string,
    pagination: IPaginationRequest,
    search: string
  ) =>
    couponCodeService.get(`/${coupon_id}/coupon-code`, {
      params: {
        perPage: pagination.per_page,
        page: pagination.page,
        search: search,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  import: (token: string, coupon_id: string, codes: string[]) =>
    couponCodeService.post(
      `/${coupon_id}/coupon-code/import?`,
      {
        codes,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  delete: (token: string, coupon_id: string, id: string | number) =>
    couponCodeService.delete(`/${coupon_id}/coupon-code/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CouponCodeServices;
