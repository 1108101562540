import axios from "axios";

const OGTimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/og-time-slot`,
});

const OGTimeSlotServices = {
  getAll: (token: string, date: string) =>
    OGTimeSlotService.get(`/list?date=${date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default OGTimeSlotServices;
