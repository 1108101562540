import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AvatarLetter from "components/common/AvatarLetter";
import { Button } from "@mui/material";
import CouponServices from "services/coupon";
import Header from "components/common/Header";
import { ICoupon } from "interfaces/coupon";
import { IPromotion } from "interfaces/promotion";
import { Settings } from "@mui/icons-material";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICouponData {
  id: number;
  name: string;
  short_description: string;
  description: string;
  image: string;
  point: number | null;
  redeem: number;
  limit: number | null;
  coupon_type_name: string;
  coupon_type_image: string;
  started_at: string;
  ended_at: string;
  last_edited_at: string;
  created_by: string;
  edited_by: string;
}

const ListCoupon = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: ICouponData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CouponServices.getAll(token as string, line_oa_code as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICoupon) => {
            return {
              id: d.id,
              name: d.name,
              short_description: d.short_description,
              description: d.description,
              image: d.image,
              point: d.point,
              redeem: d.redeem,
              limit: d.limit,
              coupon_type_name: d.coupon_type.name,
              coupon_type_image: d.coupon_type.image,
              started_at: d.started_at,
              ended: d.last_edited_at,
              last_edited_at: d.last_edited_at,
              created_by: d.admin_created.name,
              edited_by: d.admin_edited.name,
            };
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, onAlert, token, line_oa_code]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = (item: IPromotion) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการลบคูปอง <b>${item.name}</b> ใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CouponServices.delete(token as string, line_oa_code as string, item.id)
          .then((res) => {
            fetchData();
            onAlert("success", "ลบคูปองนี้สำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Coupon"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          }, 
          {
            label: "Coupon",
            href: `/coupon/only-golf/list`,
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/coupon/only-golf/config`}>
            <Button variant="contained" size="large">
              <Settings
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Config</Typography>
            </Button>
          </Link>,
          <Link to={`/coupon/only-golf/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "image",
            label: "Image",
            render: (value: string, index: number, item: ICouponData) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.name}
              />
            ),
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "short_description",
            label: "Short Description",
            render: (value: string, index: number, item: ICouponData) => {
              if (value.length > 50) {
                return <Typography>{value.substring(0, 50)}...</Typography>;
              } else {
                return <Typography>{value}</Typography>;
              }
            },
          },
          // {
          //   key: "description",
          //   label: "Description",
          //   render: (value: string, index: number, item: ICouponData) => {
          //     if (value.length > 50) {
          //       return <Typography>{value.substring(0, 50)}...</Typography>;
          //     } else {
          //       return <Typography>{value}</Typography>;
          //     }
          //   },
          // },
          {
            key: "coupon_type_image",
            label: "Coupon Type Image",
            render: (value: string, index: number, item: ICouponData) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.name}
              />
            ),
          },
          {
            key: "coupon_type_name",
            label: "Coupon Type Name",
          },
          {
            key: "point",
            label: "Point",
            render: (value: string) => (
              <Typography>{value?.toLocaleString() || "NaN"}</Typography>
            ),
          },
          {
            key: "redeem",
            label: "Usage",
            render: (value: string, index: number, item: ICouponData) => (
              <Typography>
                {item.redeem}/{item.limit === null ? "NaN" : item.limit}
              </Typography>
            ),
          },
          {
            key: "created_by",
            label: "Created By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "edited_by",
            label: "Edited By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "last_edited_at",
            label: "Last Edited At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/coupon/only-golf/${item.id}`),
          onEdit: (item: any) => navigate(`/coupon/only-golf/${item.id}/edit`),
          onDelete: (item: any) => onDelete(item),
        }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListCoupon, "MANAGE_ONLY_GOLF"));
