import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import OGServiceServices from "services/ogService";

type TOGCreateService = {
  name: string;
  duration: string;
  sequence: string;
};

const CreateService = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  const onSubmit = (data: any) => {
    let dataProps: TOGCreateService = data;
    OGServiceServices.create(token as string, {
      ...dataProps,
      duration: parseFloat(dataProps.duration),
      sequence: parseFloat(dataProps.sequence),
    })
      .then((res) => {
        onAlert("success", "สร้าง Service สำเร็จ");
        navigate(`/service/only-golf/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  return (
    <>
      <Header
        title="Create a new service"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/calendar/only-golf`,
          },
          {
            label: "Service",
            href: "/service/only-golf/list",
          },
          {
            label: "New service",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Service Name",
            type: "text",
            name: "name",
            inputOptions: {
              required: "กรุณากรอกชื่อบริการ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดชื่อที่จะแสดงผลของบริการ",
          },
          {
            label: "Duration",
            type: "number",
            name: "duration",
            inputOptions: {
              required: "กรุณากรอกระยะเวลาของบริการ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดระยะเวลาของบริการ",
          },
          {
            label: "Sequence",
            type: "number",
            name: "sequence",
            inputOptions: {
              required: "กรุณากรอกลำดับการแสดงผล",
            },
            col: 6,
            tooltip: "สำหรับกรอกลำดับการแสดงผล",
          },
        ]}
        onBack={() => navigate(`/service/only-golf/list`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreateService, "MANAGE_ONLY_GOLF"));
