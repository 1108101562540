import CouponConfig from "pages/only-golf/manage-coupon/coupon-config";
import CouponDetail from "pages/only-golf/manage-coupon/coupon/detail";
import CreateCoupon from "pages/only-golf/manage-coupon/coupon/create";
import CreatePromotion from "pages/only-golf/promotion/create";
import CreateReward from "pages/only-golf/manage-reward/reward/create";
import CreateStore from "pages/only-golf/store/create";
import Dashboard from "pages/only-golf/dashboard";
import EditCoupon from "pages/only-golf/manage-coupon/coupon/edit";
import EditMember from "pages/only-golf/manage-member/member/edit";
import EditPromotion from "pages/only-golf/promotion/edit";
import EditReward from "pages/only-golf/manage-reward/reward/edit";
import EditStore from "pages/only-golf/store/edit";
import ListCoupon from "pages/only-golf/manage-coupon/coupon/list";
import ListCouponRedemption from "pages/only-golf/manage-coupon/coupon-redemption/list";
import ListCouponType from "pages/only-golf/manage-coupon/coupon-type/list";
import ListMember from "pages/only-golf/manage-member/member/list";
import ListMemberLog from "pages/only-golf/manage-member/member-log/list";
import ListPromotion from "pages/only-golf/promotion/list";
import ListReward from "pages/only-golf/manage-reward/reward/list";
import ListRewardRedemption from "pages/only-golf/manage-reward/reward-redemption/list";
import ListStore from "pages/only-golf/store/list";
import MemberDetail from "pages/only-golf/manage-member/member/detail";
import PromotionDetail from "pages/only-golf/promotion/detail";
import RewardDetail from "pages/only-golf/manage-reward/reward/detail";
import { RouteObject } from "react-router-dom";
import StoreDetail from "pages/only-golf/store/detail";
import BookingCreate from "pages/only-golf/club-fitting/booking/create";
import BookingDetail from "pages/only-golf/club-fitting/booking/detail";
import BookingEdit from "pages/only-golf/club-fitting/booking/edit";
import BookingList from "pages/only-golf/club-fitting/booking/list";
import DashboardClubFitting from "pages/only-golf/club-fitting/dashboard";
import ServiceCreate from "pages/only-golf/club-fitting/service/create";
import ServiceDetail from "pages/only-golf/club-fitting/service/detail";
import ServiceEdit from "pages/only-golf/club-fitting/service/edit";
import ServiceList from "pages/only-golf/club-fitting/service/list";

const OnlyGolfRouters: RouteObject[] = [
  {
    path: "/dashboard/only-golf",
    element: <Dashboard />,
  },
  {
    path: "/member/only-golf/list",
    element: <ListMember />,
  },
  {
    path: "/member/only-golf/:id",
    element: <MemberDetail />,
  },
  {
    path: "/member/only-golf/:id/edit",
    element: <EditMember />,
  },
  {
    path: "/member-log/only-golf/list",
    element: <ListMemberLog />,
  },
  {
    path: "/calendar/only-golf",
    element: <DashboardClubFitting />,
  },
  {
    path: "/service/only-golf/list",
    element: <ServiceList />,
  },
  {
    path: "/service/only-golf/create",
    element: <ServiceCreate />,
  },
  {
    path: "/service/only-golf/:id/edit",
    element: <ServiceEdit />,
  },
  {
    path: "/service/only-golf/:id",
    element: <ServiceDetail />,
  },
  {
    path: "/booking/only-golf/list",
    element: <BookingList />,
  },
  {
    path: "/booking/only-golf/create",
    element: <BookingCreate />,
  },
  {
    path: "/booking/only-golf/:id/edit",
    element: <BookingEdit />,
  },
  {
    path: "/booking/only-golf/:id",
    element: <BookingDetail />,
  },
  {
    path: "/store/only-golf/list",
    element: <ListStore />,
  },
  {
    path: "/store/only-golf/:id",
    element: <StoreDetail />,
  },
  {
    path: "/store/only-golf/create",
    element: <CreateStore />,
  },
  {
    path: "/store/only-golf/:id/edit",
    element: <EditStore />,
  },
  {
    path: "/promotion/only-golf/list",
    element: <ListPromotion />,
  },
  {
    path: "/promotion/only-golf/:id",
    element: <PromotionDetail />,
  },
  {
    path: "/promotion/only-golf/create",
    element: <CreatePromotion />,
  },
  {
    path: "/promotion/only-golf/:id/edit",
    element: <EditPromotion />,
  },
  {
    path: "/reward/only-golf/list",
    element: <ListReward />,
  },
  {
    path: "/reward/only-golf/:id",
    element: <RewardDetail />,
  },
  {
    path: "/reward/only-golf/create",
    element: <CreateReward />,
  },
  {
    path: "/reward/only-golf/:id/edit",
    element: <EditReward />,
  },
  {
    path: "/reward-redemption/only-golf/list",
    element: <ListRewardRedemption />,
  },
  {
    path: "/coupon-type/only-golf/list",
    element: <ListCouponType />,
  },
  {
    path: "/coupon/only-golf/config",
    element: <CouponConfig />,
  },
  {
    path: "/coupon/only-golf/list",
    element: <ListCoupon />,
  },
  {
    path: "/coupon/only-golf/:id",
    element: <CouponDetail />,
  },
  {
    path: "/coupon/only-golf/create",
    element: <CreateCoupon />,
  },
  {
    path: "/coupon/only-golf/:id/edit",
    element: <EditCoupon />,
  },
  {
    path: "/coupon-redemption/only-golf/list",
    element: <ListCouponRedemption />,
  },
];

export default OnlyGolfRouters;
