import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import { IOGService } from "interfaces/ogService";
import OGServiceServices from "services/ogService";

type TEditService = {
  name: string;
  duration: string;
  sequence: string;
};

const EditService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [service, setService] = useState<IOGService | null>(null);

  useEffect(() => {
    const fetchData = () => {
      OGServiceServices.getDetail(token as string, id as string)
        .then((res) => {
          setService(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = (data: TEditService) => {
    OGServiceServices.edit(token as string, id as string, {
      ...data,
      duration: parseInt(data.duration),
      sequence: parseInt(data.sequence),
    })
      .then((res) => {
        onAlert("success", "แก้ไข Service สำเร็จ");
        navigate(`/service/only-golf/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (service) {
    return (
      <>
        <Header
          title="Edit Service"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/calendar/only-golf`,
            },
            {
              label: "Service",
              href: `/service/only-golf/list`,
            },
            {
              label: service.name,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Service Name",
              type: "text",
              name: "name",
              inputOptions: {
                required: "กรุณากรอกชื่อบริการ",
              },
              defaultValue: service.name,
              col: 6,
              tooltip: "สำหรับกำหนดชื่อที่จะแสดงผลของบริการ",
            },
            {
              label: "Duration (Min)",
              type: "number",
              name: "duration",
              inputOptions: {
                required: "กรุณากรอกระยะเวลาให้บริการ",
              },
              defaultValue: service.duration,
              col: 6,
              tooltip: "สำหรับกรอกระยะเวลาให้บริการ",
            },
            {
              label: "Sequence",
              type: "number",
              name: "sequence",
              inputOptions: {
                required: "กรุณากรอกลำดับ",
              },
              defaultValue: service.sequence,
              col: 6,
              tooltip: "สำหรับกรอกลำดับ",
            },
          ]}
          onBack={() => navigate(`/service/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditService, "MANAGE_ONLY_GOLF"));
