import CouponServices, { TCreateCouponData } from "services/coupon";
import { useEffect, useState } from "react";

import CouponTypeServices from "services/couponType";
import CreateFieldList from "components/manage-coupon/coupon/CreateFieldList";
import FileServices from "services/file";
import Header from "components/common/Header";
import { ICouponType } from "interfaces/couponType";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import { couponModes } from "./constants";

type TCreateCoupon = {
  prefix: string;
  name: string;
  mode: string;
  confirm_code: string;
  image: FileList | undefined;
  short_description: string;
  description: string;
  description_clone: string;
  point: string;
  limit: string;
  limit_per_user: string;
  is_user_per_month: string;
  started_at: Date;
  ended_at: Date;
  coupon_type_id: string;
};

const CreateCoupon = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [couponTypes, setCouponTypes] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);

  useEffect(() => {
    const fetchData = () => {
      CouponTypeServices.getAll(token as string, line_oa_code, {
        page: 1,
        per_page: 100,
      }).then((res) => {
        setCouponTypes(
          res.data.data.map((couponType: ICouponType) => {
            return {
              label: couponType.name,
              value: couponType.id,
            };
          })
        );
      });
    };

    fetchData();
  }, [token, line_oa_code]);

  const onSubmit = async (values: TCreateCoupon) => {
    let data: TCreateCouponData = {
      prefix: values.prefix || null,
      mode: values.mode,
      confirm_code: values.confirm_code || null,
      name: values.name,
      image: null,
      short_description: values.short_description.replace("/\r?\n/gi", ""),
      description: values.description_clone,
      point: parseInt(values.point),
      limit: null,
      limit_per_user: null,
      is_user_per_month: false,
      started_at: dayjs(values.started_at).format("YYYY-MM-DD"),
      ended_at: dayjs(values.ended_at).format("YYYY-MM-DD"),
      coupon_type_id: parseInt(values.coupon_type_id),
    };
    if (values.mode === "FIXED") {
      data.limit = 0;
    } else if (values.limit !== "") {
      data.limit = parseInt(values.limit);
    }
    if (values.limit_per_user !== "") {
      data.limit_per_user = parseInt(values.limit_per_user);
    }
    if (values.is_user_per_month === "yes") {
      data.is_user_per_month = true;
    }
    let isError = false;
    if (values.image && values.image[0]) {
      const MAX_SIZE_MB = 1;
      const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
      if (values.image[0].size <= MAX_SIZE_BYTES) {
        let formData = new FormData();
        formData.append("file", values.image[0]);
        formData.append("prefix", "coupon");
        formData.append("path", "/coupon");
        await FileServices.create(token as string, formData)
          .then((res) => {
            data.image = res.data.data;
          })
          .catch((err) => {
            isError = true;
            onAlert("error", err.response.data.message);
          });
      } else {
        isError = true;
        onAlert("error", "ขนาดรูปต้องไม่เกิน 1MB");
      }
    }
    if (!isError) {
      CouponServices.create(token as string, line_oa_code as string, data)
        .then((res) => {
          onAlert("success", "สร้างคูปองสำเร็จ");
          navigate(`/coupon/only-golf/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (couponTypes) {
    return (
      <>
        <Header
          title="Create a new coupon"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Coupon",
              href: `/coupon/only-golf/list`,
            },
            {
              label: "New coupon",
            },
          ]}
        />
        <CreateFieldList
          onSubmit={onSubmit}
          fields={[
            {
              label: "Cover Image (Max size 1MB)",
              type: "image",
              name: "image",
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูลรูปภาพแสดงผลของคูปอง",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Type",
              type: "select",
              name: "coupon_type_id",
              inputOptions: {
                required: "กรุณาเลือกประเภทของคูปอง",
              },
              col: 6,
              items: couponTypes,
              defaultValue: "",
              tooltip: "ตัวเลือกสำหรับกำหนดประเภทของคูปอง",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Mode",
              type: "select",
              name: "mode",
              inputOptions: {
                required: "กรุณาเลือกโหมดของคูปอง",
              },
              col: 6,
              items: couponModes,
              defaultValue: "",
              tooltip: "ตัวเลือกสำหรับกำหนดโหมดของคูปอง",
            },
            {
              label: "Confirm Code",
              type: "text",
              name: "confirm_code",
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูลยืนยันโค้ด",
            },
            {
              label: "Coupon Name",
              type: "text",
              name: "name",
              inputOptions: {
                required: "กรุณากรอกชื่อ",
              },
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูลชื่อคูปอง",
            },
            {
              label: "Coupon Prefix",
              type: "text",
              name: "prefix",
              inputOptions: {
                required: "กรุณากรอกคำนำหน้าชื่อ",
                pattern: {
                  value: /[A-Z]/,
                  message: "กรุณากรอกตัวอักษร A-Z",
                },
                minLength: {
                  value: 5,
                  message: "กรุณากรอก 5 ตัวอักษร",
                },
              },
              col: 6,
              tooltip: "สำหรับกำหนดคำนำหน้าชื่อ",
              maxLength: 5,
            },
            {
              label: "Point",
              type: "number",
              name: "point",
              inputOptions: {
                required: "กรุณากรอกคะแนนที่ใช้",
                min: {
                  message: "กรุณากรอก 0 ขึ้นไป",
                  value: 0,
                },
              },
              col: 6,
              tooltip:
                "สำหรับกำหนดคะแนนคูปองที่ลูกค้าต้องใช้ในการใช้งานคูปอง โดยมีขั้นต่ำคือ 0 คะแนน",
            },
            {
              label: "",
              type: "box",
              name: "free_space_point",
              col: 6,
            },
            {
              label: "Start At",
              type: "date",
              name: "started_at",
              inputOptions: {
                required: "กรุณาเลือกวันที่",
              },
              col: 6,
              tooltip:
                "สำหรับกำหนดวันที่จะเริ่มการแสดงผล และให้ลูกค้าใช้งานคูปองบน LINE OA",
              defaultValue: dayjs(new Date()).format("YYYY-MM-DD"),
            },
            {
              label: "End At",
              type: "date",
              name: "ended_at",
              inputOptions: {
                required: "กรุณาเลือกวันที่",
              },
              col: 6,
              tooltip:
                "สำหรับกำหนดวันที่จะสิ้นสุดการแสดงผล และให้ลูกค้าใช้งานคูปองบน LINE OA",
              defaultValue: dayjs(
                new Date().setDate(new Date().getDate() + 1)
              ).format("YYYY-MM-DD"),
            },
            {
              label: "Short Description",
              type: "textarea",
              name: "short_description",
              inputOptions: {
                required: "กรุณากรอกคำอธิบายสั้น",
              },
              col: 6,
              maxLength: 100,
              tooltip: "สำหรับกำหนดรายละเอียดคูปองโดยย่อในหน้าตัวเลือกคูปอง",
            },
            {
              label: "Long Description",
              type: "text-editor",
              name: "description",
              inputOptions: {
                required: "กรุณากรอกคำอธิบาย",
              },
              col: 6,
              maxLength: 250,
              tooltip:
                "สำหรับกำหนดรายละเอียดคูปอง เมื่อลูกค้าทำการกดเลือกใช้งานคูปอง",
            },

            {
              label: "1 Time Per Month",
              type: "radiogroup",
              name: "is_user_per_month",
              inputOptions: {
                required: "กรุณาเลือก",
              },
              col: 6,
              items: [
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ],
              tooltip:
                "ตัวเลือกสำหรับกำหนดว่าคูปองสามารถใช้ได้ 1 ครั้งต่อเดือนหรือไม่ (Yes = ใช้ได้ 1 ครั้งต่อเดือน / No = ไม่มีจำกัดต่อเดือน)",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Supply",
              type: "number",
              name: "limit",
              col: 6,
              tooltip: "สำหรับกำหนดจำนวนสิทธิ์คูปองทั้งหมดที่ให้ลูกค้าใช้ได้",
              inputOptions: {
                min: {
                  message: "กรุณากรอก 0 ขึ้นไป",
                  value: 0,
                },
              },
            },
            {
              label: "Limit Per User",
              type: "text",
              name: "limit_per_user",
              col: 6,
              tooltip:
                "สำหรับกำหนดจำนวนครั้งที่ลูกค้าสามารถใช้คูปองซ้ำได้ โดยขั้นต่ำคือ 1 ครั้ง",
              inputOptions: {
                min: {
                  message: "กรุณากรอก 0 ขึ้นไป",
                  value: 0,
                },
              },
            },
          ]}
          onBack={() => navigate(`/coupon/only-golf/list`)}
        />
      </>
    );
  }
};

export default withAuth(withPermission(CreateCoupon, "MANAGE_ONLY_GOLF"));
