import { Box, Divider, Grid, Paper } from "@mui/material";

import Field from "components/common/FieldList/Field";
import Footer from "components/common/FieldList/Footer";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

type TField = {
  label: string;
  type: string;
  name: string;
  defaultValue?: any;
  disabled?: boolean;
  inputOptions?: any;
  maxLength?: number;
  pattern?: string;
  tooltip?: string;
  col?: number;
  items?: {
    label: any;
    value: any;
    checked?: boolean;
  }[];
};

interface Props {
  fields: TField[];
  onSubmit: (data: any) => void;
  onBack: () => void;
}

const EditFieldList: React.FC<Props> = ({ onSubmit, fields, onBack }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    setValue("coupon_type_id", fields[2].defaultValue);
    setValue("mode", fields[4].defaultValue);
  }, []);

  const onConfirm = (data: any) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการแก้ไขใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(data);
      }
    });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onConfirm)}>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              let show = true;
              let disabled = field.disabled;
              let inputOptions = {...field.inputOptions};
              if(field.name === "mode") {
                disabled = true;
              }
              if (
                (field.name === "prefix" || field.name === "limit") &&
                watch("mode") === "FIXED" && watch("mode")
              ) {
                disabled = true;
                inputOptions = { ...field.inputOptions, required: "" };
              }
              if (
                field.name === "is_user_per_month" ||
                field.name === "limit" ||
                field.name === "limit_per_user"
              ) {
                fields[2].items?.forEach((coupon_type) => {
                  if (
                    coupon_type.label === "BIRTH_DATE" &&
                    coupon_type.value === watch("coupon_type_id")
                  ) {
                    show = false;
                  }
                });
              }
              if (field.name === "point" || field.name === "free_space_point") {
                fields[2].items?.forEach((coupon_type) => {
                  if (
                    coupon_type.label === "SPECIAL" &&
                    coupon_type.value === watch("coupon_type_id")
                  ) {
                    show = false;
                  }
                });
              }
              let minDate = undefined;
              let maxDate = undefined;
              if (field.name === "started_at") {
                if (watch("ended_at")) {
                  let endedAt = new Date(watch("ended_at"));
                  maxDate = new Date(endedAt.setDate(endedAt.getDate() - 1));
                }
              }
              if (field.name === "ended_at") {
                if (watch("started_at")) {
                  let startedAt = new Date(watch("started_at"));
                  minDate = new Date(
                    startedAt.setDate(startedAt.getDate() + 1)
                  );
                }
              }
              if (show) {
                return (
                  <Grid key={index + 1} item xs={field.col || 12}>
                    <Field
                      {...field}
                      fieldListType={"edit"}
                      inputOptions={{ ...inputOptions, minDate, maxDate }}
                      disabled={disabled}
                      form={{
                        register,
                        setValue,
                        watch,
                        setError,
                        clearErrors,
                      }}
                      error={!!errors[field.name]}
                      errorMessage={errors[field.name]?.message as string}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Box>
        <Divider />
        <Footer type={"edit"} onBack={onBack} />
      </form>
    </Paper>
  );
};

export default EditFieldList;
